<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VToolbar flat>
            <VBtn
              icon
              depressed
              @click="$router.go(-1)"
            >
              <VIcon>fal fa-chevron-left</VIcon>
            </VBtn>

            <VToolbarTitle> Команды {{ company.name }} </VToolbarTitle>

            <VSpacer />

            <VBtn
              color="primary"
              :to="{ name : Names.R_ORGSTRUCTURE_COMPANY_TEAM_ADD }"
            >
              <VIcon left>
                fal fa-plus
              </VIcon>

              Создать команду
            </VBtn>
          </VToolbar>

          <VDivider />

          <VCardText>
            <VExpansionPanels
              accordion
              flat
            >
              <VExpansionPanel>
                <VExpansionPanelHeader>JSON</VExpansionPanelHeader>
                <VExpansionPanelContent>
                  <code class="elevation-0 d-block">{{ company }}</code>
                </VExpansionPanelContent>
              </VExpansionPanel>
            </VExpansionPanels>

            <VDialog
              v-model="confirmDialog"
              max-width="500px"
              @click:outside="handleCancel"
            >
              <VCard>
                <VCardTitle class="headline">
                  Удалить команду?
                </VCardTitle>

                <VCardText>
                  Команда <span class="red--text">{{ team.name }}</span> будет удалена! <br>
                  Действительно хотите удалить команду?
                </VCardText>

                <VCardActions>
                  <VSpacer />

                  <VBtn
                    text
                    @click="handleCancel"
                  >
                    Нет
                  </VBtn>

                  <VBtn
                    color="red darken-1"
                    text
                    @click="handleDelete"
                  >
                    Да
                  </VBtn>
                </VCardActions>
              </VCard>
            </VDialog>

            <VDataTable
              :items="teams"
              :headers="teamsHeaders"
            >
              <template #item.name="{ item : { id, name } }">
                <RouterLink
                  class="d-block"
                  :to="{
                    name : Names.R_ORGSTRUCTURE_COMPANY_TEAM,
                    params : {
                      companyId : cid,
                      teamId : id
                    }
                  }"
                >
                  {{ name }}
                </RouterLink>
              </template>

              <template #item.actions="{ item }">
                <VTooltip left>
                  <template #activator="{ on, attrs }">
                    <VBtn
                      icon
                      small
                      color="orange"
                      class="mx-1"
                      :to="{
                        name : Names.R_ORGSTRUCTURE_COMPANY_TEAM_EDIT,
                        params : {
                          companyId : cid,
                          teamId : item.id
                        }
                      }"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <VIcon small>
                        fal fa-edit
                      </VIcon>
                    </VBtn>
                  </template>

                  <span>Редактирование</span>
                </VTooltip>
                <VTooltip left>
                  <template #activator="{ on, attrs }">
                    <VBtn
                      icon
                      small
                      color="red"
                      class="mx-1"
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="deleteTeamFromCompany(item)"
                    >
                      <VIcon small>
                        fal fa-trash
                      </VIcon>
                    </VBtn>
                  </template>

                  <span>Удаление</span>
                </VTooltip>
              </template>
            </VDataTable>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'OrgCompany',
  inject: ['Names'],
  data() {
    return {
      company: {},
      teams: [],
      teamsHeaders: [
        {
          text: 'Название',
          value: 'name',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'actions',
        },
      ],
      confirmDialog: false,
      team: {},
      loading: false,
    };
  },

  computed: {
    cid() {
      return this.$route.params.id;
    },
  },

  created() {
    this.fetch();
  },

  methods: {
    fetch() {
      const { cid: id } = this;

      this.$di.api.Orgstructure
        .teamIndex(id)
        .then((r) => {
          this.teams = r.teams;
        })
        .catch(this.$di.notify.errorHandler);

      this.$di.api.Account
        .getCompany({ id })
        .then((r) => {
          this.company = r.company;
        })
        .catch((err) => {
          this.$di.notify.errorHandler(err);
          this.$di.redirect.errorHandler404(err);
        });
    },

    deleteTeamFromCompany(item) {
      this.confirmDialog = true;
      this.team = { ...item };
    },

    async handleDelete() {
      try {
        this.loading = true;
        const { id } = this.team;
        const data = { id, companyId: this.cid };

        await this.$di.api.Orgstructure.teamDelete(data);

        this.resetTeamDelete();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        // no-finally
        this.loading = false;
        this.fetch();
      }
    },

    handleCancel() {
      this.resetTeamDelete();
    },

    resetTeamDelete() {
      this.confirmDialog = false;
      this.team = {};
    },
  },
};
</script>
